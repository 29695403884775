* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  height: 100%; }

#root {
  height: 100%; }

::-webkit-scrollbar {
  width: 5px; }

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 10px; }

.text-black {
  color: black; }