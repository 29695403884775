/* Provide sufficient contrast against white background */
html {
  min-height: 100%;
  height: 100%; }

  .toastr{
    z-index: 99999;
    box-shadow: none;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    padding: 0px 16px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    min-height: unset !important;
  }
  
.redux-toastr .toastr .rrt-left-container{
  display: none !important;
}

.redux-toastr .toastr .rrt-middle-container{
  margin-left: 0px !important;
}